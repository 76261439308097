/* eslint-disable react/jsx-filename-extension */
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Embaixadores from '../containers/Embaixadores';

const EmbaixadoresPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      embaixadoresJson {
        hero {
          title
          subtitle
          description
          backgroundImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 601, maxHeight: 715, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
          actionLabel
          actionLink
        }
      }
    }
  `);

  const courseData = data.embaixadoresJson.hero;

  return (
    <Embaixadores
      title="Curso Preparatório para Carreiras Militares - Mais Militar"
      description="Está buscando fazer carreira militar? Com o Mais Militar você tem tudo o que precisa para buscar a aprovação nos concursos militares. Saiba mais!"
      location={location}
      data={courseData}
    />
  );
};

EmbaixadoresPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default EmbaixadoresPage;
