import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/seo';
import Hero from '../../components/hero';
import Footer from '../Layout/Footer';
import SimpleHeader from '../Layout/SimpleHeader';

const Embaixadores = ({ location, data }) => {
  const { title, subtitle, description, image, backgroundImage, actionLabel, actionLink } = data;
  const action = {
    label: actionLabel,
    link: actionLink,
  };
  return (
    <>
      <SEO title={title} description={description} location={location} image={image.childImageSharp.fluid.src} />
      <SimpleHeader />
      <Hero
        title={title}
        subtitle={subtitle}
        description={description}
        action={action}
        image={image.childImageSharp.fluid}
        backgroundImage={backgroundImage.childImageSharp.fluid}
        dark
      />
      <Footer />
    </>
  );
};

Embaixadores.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    actionLabel: PropTypes.string.isRequired,
    actionLink: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    backgroundImage: PropTypes.object.isRequired,
  }).isRequired,
};

export default Embaixadores;
